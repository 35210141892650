<template v-if=" product">
    <li v-bind:value="JSON.stringify({
        id: product.id,
        name: product.name,
        price: product.price,
        cement:product.cement,
        stone_dust: product.stone_dust
        
    })">
        <div class="columns is-mobile">
            <div class="column is-4">
                <figure class="mb-4">
                    <img v-bind:src="product.image" alt="">
                </figure>
                
                {{ product.name }}
            </div>
            <div class="column is-3 is-centered mt-5">
                {{product.type}}
            </div>
            <div class="column is-5 pt-5 field has-addons"> 
                <div class="control">
                <input type="number" class="input" @change="addToCart()"
                    placeholder="Enter"
                    v-model="quantity"              
                    >
                </div>

                <!--

                    <div class="control" id="change">
                        
                    
                        <a class="button is-success" style="background-color:#004100" @click="addToCart()">
                            Add
                    </a>
                    
                </div>
                -->
            </div>

        </div>
        <hr class="mt-0">
    </li>
    

</template>

<script>
import swal from 'sweetalert';
import { toast } from 'bulma-toast';
import { checkradio } from 'bulma-checkradio';

    export default{
        name: 'ProductBox',
        props:{
            product: Object,
            
        },
        
        emits:['update:quantity'],
        data(){
            return {
                quantity: '',
                cart:{
                    items:[]
            },
        }
        },
        mounted(){
            this.cart = this.$store.state.cart
        },
        methods: {
            addToCart(){
                if (isNaN(this.quantity) || this.quantity < 1){
                this.quantity = 0
            }

            if(this.product.type == "Retainer"){
                const planter = {
                    product: this.product,
                    quantity: this.quantity
                }

                this.$store.commit('setPlanter', planter)
            }
            /*
            if(this.quantity < 4000){
                toast({
                message: 'The number of blocks is too low to deploy our industrial scale machines to your site, however contact us to deliver the blocks to you',
                type: 'is-success',
                dismissible: true,
                duration: 5000,
                position: 'bottom-right',
            })
            }*/

            //use of sweet alert for modals!!!!!
            const item = {
                product: this.product, //covert stringified product to JSON
                quantity: this.quantity
            }

   
            this.$store.commit('addToCart', item)

            const total_quantity = this.cart.items.reduce((acc, curVal) => {
                return  acc += curVal.quantity
            },0)

            this.$store.commit('setTotalQuantity', total_quantity)

            console.log(item)

            }

    },
    }
</script>
    
<style scoped>
.high{
    height: 150px;
}

</style>