<template>
    <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
    <header class="modal-card-head">
    <p class="modal-card-title">Materials MarketPlace</p>
    <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
        <table class="table">
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
            <tr class="cementMaterial">
                <td><strong>Cement:</strong></td>
                <td>bags</td>
                <td>
                <a @click="decrementCement()">-</a>
                {{this.cement_quantity}}
                <a @click="incrementCement()">+</a>
                </td>
                <td>{{CementCost.toLocaleString()}}</td>
                <td>{{this.cement_total_cost.toLocaleString()}}</td>
                <!--<td style="border: 0px"><button class="delete" id="getCementMaterialItem" @click="removeCementMaterial()"></button></td>-->
            </tr>
            <tr class="stoneDustMaterial">
                <td><strong>Stone Dust:</strong></td>
                <td>tonnes</td>
                <td>
                    <a @click="decrementStone()">-</a>
                    {{this.stone_quantity}}
                    <a @click="incrementStone()">+</a>
                </td>
                <td>{{StoneDustCost.toLocaleString()}}</td>
                <td>{{this.stone_total_cost.toLocaleString()}}</td>
                <!--<td style="border: 0px"><button class="delete" id="getStoneDustMaterialItem" @click="removeStoneDustMaterial()"></button></td>-->
            </tr>
            <tr style="background-color:#D9D9D9">
                <th colspan="4">Material Cost</th>
                <td class="totalMaterialCost">{{(this.cement_total_cost + this.stone_total_cost).toLocaleString()}}</td>
            </tr>
            </tbody>
        </table>
    </section>
    <footer class="modal-card-foot">
    <button class="button is-warning close">Cancel</button>
    <button class="button is-success">Make Payment</button>
    </footer>
    </div>
</div>

</template>

<script>
import { throwStatement } from '@babel/types'

export default {
    name: 'MaterialCartItem',
    components: {
        
    },
    data(){
        return {
            cart: {
                items:[]
            },
            cement_quantity:'',
            stone_quantity:'',
            cement_total_cost:'',
            stone_total_cost:'',

        }
    },
    mounted(){
        this.cart = this.$store.state.cart
        //Obtain total cement quantity
        this.cement_quantity = this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.cement)
        }, 0)
        //set store state of cement quantity
        this.$store.commit('setCementQuantity', this.cement_quantity)

        //Obtain total stone dust quantity
        this.stone_quantity = this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.stone_dust)
        }, 0)
        //set store state of stone dust quantity
        this.$store.commit('setStoneQuantity', this.stone_quantity)

        //Obtain total cement cost
        this.cement_total_cost = parseInt(this.$store.state.cement_price) * this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.cement)
        }, 0)
        this.$store.commit('setCementTotalCost', this.cement_total_cost)

        this.stone_total_cost = parseInt(this.$store.state.stone_dust_price) * this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.stone_dust)
        }, 0)
        this.$store.commit('setStoneTotalCost', this.stone_total_cost)
          
    },
    computed: {
    CementCost(){
            return this.$store.getters.cement_price
        },
    StoneDustCost(){
        return this.$store.getters.stone_dust_price
    },
    

    },
    methods:{
        decrementCement(){
            this.cement_quantity -= 1
            this.cement_total_cost -= parseInt(this.$store.state.cement_price)
            this.$store.commit('setCementQuantity', this.cement_quantity)
            this.$store.commit('setCementTotalCost', this.cement_total_cost)
        },
        incrementCement(){
            this.cement_quantity += 1
            this.cement_total_cost += parseInt(this.$store.state.cement_price)
            this.$store.commit('setCementQuantity', this.cement_quantity)
            this.$store.commit('setCementTotalCost', this.cement_total_cost)  
        },
        decrementStone(){
            this.stone_quantity -= 1
            this.stone_total_cost -= parseInt(this.$store.state.stone_dust_price)
            this.$store.commit('setStoneQuantity', this.stone_quantity)
            this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        },
        incrementStone(){
            this.stone_quantity += 1
            this.stone_total_cost += parseInt(this.$store.state.stone_dust_price)
            this.$store.commit('setStoneQuantity', this.stone_quantity)
            this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        }
    }

}
</script>

<style scoped>
th, td{
    padding: 4px
}
</style>