<template>
    <tr>
        <td><strong>{{ item.emitter_material.name }}</strong></td>
        <td>{{ item.distance }}</td>
        <td>{{ item.mode }}</td>
        <td>{{ item.number_of_trips }}</td>
        <td>{{ item.fuel_efficiency }}</td>
        <td> {{ getFuelOnJourney(item).toFixed(2) }}</td>
        <td>2.86767</td>
        <td>{{ getItemEmission(item).toFixed(2) }}</td>

    </tr>
</template>

<script>
export default {
    name : "EmitterMatItem",
    props:{
        initialItem: Object
    },
    data(){
        return {
            item: this.initialItem,
            carbon_cart: {
                emit_mats:[]
            }
        }
    },
    mounted(){
        this.carbon_cart = this.$store.state.carbon_cart
    },
    methods:{
        getFuelOnJourney(item){
            return (item.distance * (item.fuel_efficiency / 100) * item.number_of_trips * 2)
        },
        getItemEmission(item){
            return 2.86767 * (item.distance * (item.fuel_efficiency / 100) * item.number_of_trips * 2)
        }
    }

}
</script>

<style scoped>
th, td{
    padding: 4px;
}
</style>