<template>
<div class="section">
<h4 class="has-text-left has-text-weight-bold">Carbon Footprint Calculator For a Building</h4>
<div class="has-text-left">
    <h3><strong>SITE LOCATION</strong></h3>
</div>   
    <vue-google-autocomplete
        ref="address"
        id="map"
        class="input"
        placeholder="Enter site location for the building"
        v-on:placechanged="getAddressData"
        country="ug"
        types=""
        >
    </vue-google-autocomplete>
    <div class="section">
        <h5 class="has-text-left has-text-weight-bold">Emissions due to Transportation</h5>
        <div class="columns is-mobile has-text-left has-text-weight-bold">
            <div class="column is-3">
              <h6> <strong>Item</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Source of materials</strong></h6>
            </div>
            
            <div class="column is-3">
              <h6><strong>Mode of Transport</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>No. of trips</strong></h6>
            </div>
            <hr>
        </div>
            <ul id="emits" :key="updateEmitterMaterials">
            <template v-if="emitter_materials">
              <EmitterMaterial
                  v-for="emitter_material in emitter_materials"
                  v-bind:key="emitter_material.id"
                  v-bind:emitter_material="emitter_material"
                />
            </template>
            <template v-else>
              <ProductLoading />
            </template>
            </ul>
    </div>
    <div class="section pt-1">
        <h5 class="has-text-left has-text-weight-bold">Emissions due to Materials Used</h5>
        <div class="columns is-mobile has-text-left has-text-weight-bold">
            <div class="column is-3">
              <h6> <strong>Material</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Classification</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Units</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Amount</strong></h6>
            </div>
        </div>
            <ul id="emits" :key="updateEmitterManufacture">
            <template v-if="material_manufactures">
              <EmitterManufacture
                  v-for="material_manufacture in material_manufactures"
                  v-bind:key="material_manufacture.id"
                  v-bind:material_manufacture="material_manufacture"
                />
            </template>
            <template v-else>
              <ProductLoading />
            </template>
            </ul>

    </div>
    <div class="section pt-1">
        <h5 class="has-text-left has-text-weight-bold">Emissions due to Construction operations</h5>
        <div class="columns is-mobile has-text-left has-text-weight-bold">
            <div class="column is-3">
              <h6> <strong>Stage</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Equipment</strong></h6>
            </div>   
            <div class="column is-3">
              <h6><strong>Fuel type</strong></h6>
            </div>
            <div class="column is-3">
              <h6><strong>Amount</strong></h6>
            </div>
        </div>
        <ul id="stages" :key="updateEmitterStages">
            <template v-if="emitter_stages">
                <EmitterStage
                    v-for="emitter_stage in emitter_stages"
                    v-bind:key="emitter_stage.id"
                    v-bind:emitter_stage="emitter_stage"
                />
            </template>
            <template v-else>
              <ProductLoading />
            </template>
        </ul>
    </div>
    <div class="section pt-1">
        <h5 class="has-text-left has-text-weight-bold">Emissions due to Electricity consumption</h5>
        <EmitterElectricity />
    </div>

    <div class="section">
        <div>
        <router-link to="/carbon-footprint-results" @click.native="scrollToTop">
          <a class="button is-success pb-2">
            Calculate Total Emissions
          </a>
        </router-link>
      </div>
    </div>


</div>

</template>

<script>
import EmitterMaterial from "@/components/EmitterMaterial.vue";
import EmitterStage from "@/components/EmitterStage.vue";
import EmitterManufacture from "@/components/EmitterManufacture.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ProductLoading from "@/components/ProductLoading.vue"
import { mapActions, mapGetters } from 'vuex';
import EmitterElectricity from "@/components/EmitterElectricity.vue";
import store from "@/store";
export default{
    name : "CarbonIndex",
    components:{
    VueGoogleAutocomplete,
    EmitterMaterial,
    EmitterStage,
    EmitterManufacture,
    ProductLoading,
    EmitterElectricity
},
    data(){
        return{
            emitter_material:{},
            emitter_stage:{},
            updateEmitterMaterials:0,
            updateEmitterStages:0,
            updateEmitterManufacture:0,
        }
    },
    computed:{
        emitter_materials(){
            return this.$store.getters.emitter_materials
      },
        emitter_stages(){
            return this.$store.getters.emitter_stages
        },
        material_manufactures(){
            return this.$store.getters.material_manufactures
        },
    },
    created(){
        this.fetchAllMaterials();
        this.fetchAllStages();
        this.fetchAllMaterialManufacture();
      },
    methods:{
        ...mapActions(["fetchAllMaterials"]),
        ...mapActions(["fetchAllStages"]),
        ...mapActions(["fetchAllMaterialManufacture"]),

        getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
        this.placeResultData = placeResultData

        const carbon_site_location = this.address
        store.commit('setCarbonSiteLocation', carbon_site_location)  
      },
    }
}
</script>

<style>

</style>