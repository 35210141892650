<template v-if="emitter_stage">
  <li
    v-bind:value="
      JSON.stringify({
        id: emitter_stage.id,
        name: emitter_stage.name,
        fuel_type: emitter_stage.fuel_type,
        emission_factor: emitter_stage.emission_factor,
        activity: emitter_stage.activity,
      })
    "
  >
    <div class="columns is-mobile has-text-left">
      <div class="column is-3">
        <h5 class="has-text-weight-medium">
          {{ emitter_stage.activity.stage.name }}
        </h5>
      </div>
      <div class="column is-3">
        <h5 class="has-text-weight-medium">{{ emitter_stage.name }}</h5>
        <p>{{ emitter_stage.activity.name }}</p>
      </div>
      <div class="column is-3">
        <div class="dropdown" id="drop">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onclick="this.parentNode.parentNode.classList.toggle('is-active')"
            >
              <span v-if="this.selectedValue == ''">Diesel:</span>
              <span v-else>{{ selectedValue }}</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                class="dropdown-item"
                v-for="option in options"
                :key="option.id"
                @click="selectOption(option)"
              >
                {{ option.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <input
          type="number"
          class="input"
          @change="addToCart()"
          placeholder="Enter number of litres"
          v-model.number="number_of_litres"
        />
      </div>
    </div>
  </li>
  <hr />
</template>

<script>
export default {
  name: "EmitterStage",
  props: {
    emitter_stage: Object,
  },
  data() {
    return {
      number_of_litres: "",
      selectedValue: "Diesel",
      emission_factor: 2.86767,
      units: "litres",
      options: [
        { id: 1, label: "Diesel", emission_factor: 2.86767, units: "litres" },
        { id: 2, label: "Petrol", emission_factor: 2.3, units: "litres" },
        { id: 3, label: "Electricity", emission_factor: 0.58, units: "kwH" },
      ],
    };
  },
  methods: {
    selectOption(option) {
      this.selectedValue = option.label;
      this.emission_factor = option.emission_factor;
      this.units = option.units;
    },
    addToCart() {
      if (isNaN(this.number_of_litres) || this.number_of_litres < 1) {
        this.number_of_litres = 1;
      }
      const item = {
        emitter_stage: this.emitter_stage,
        fuel_type: this.selectedValue,
        number_of_litres: this.number_of_litres,
        emission_factor: this.emission_factor,
        units: this.units,
      };
      console.log(item);
      this.$store.commit("addToCarbonCartStage", item);
    },
  },
};
</script>
