<template>
<div class="columns is-mobile has-text-left">
        <div class="column is-6">   
            <h5 class="has-text-weight-medium">Electricity</h5>
        </div>
        <div class="column is-6">
            <div class="control">
                <input type="number" class="input" @change="addToCart()"
                    placeholder="Enter of kWh consumed"
                    v-model="amount_of_electricity"                  
                >
            </div> 
        </div>
    </div>
</template>

<script>
export default{
    name : "EmitterElectricity",
    data(){
        return{
            amount_of_electricity:'',
        }
    }, 
    methods:{
        addToCart(){
            if (isNaN(this.amount_of_electricity) || this.amount_of_electricity < 1){
                this.amount_of_electricity = 1
            }
            this.$store.commit("setCarbonCartElectricity", this.amount_of_electricity)
        }
    }
}
</script>
