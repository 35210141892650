<template>

</template>

<script>
export default{
    name: 'CarbonResults',
    components: {

    },
    data(){
        return{

        }
    },
    methods: {
        
    }
}
</script>