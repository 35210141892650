<template>
    <tr v-if="item.quantity">
        <td>{{item.product.name}} {{item.product.type}}</td>
        <td>{{item.quantity}}</td>
        <td>{{getLabourUnitPrice(item)}}</td>
        <td class="pr-0">{{getLabourPrice(item).toLocaleString()}}</td>
    </tr>
</template>

<script>
    export default {
        name: 'LabourItem',
        props:{ 
            initialItem: Object
        },
        data(){
            return {
                item:this.initialItem
            }
        },
        methods:{
            getLabourPrice(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    )))
                } else {
                    return (Math.ceil(item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    )))
                }
            },
            getLabourUnitPrice(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    ))
                } else {
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    ))
                }
            }
        }
    }
</script>

<style scoped>
td{
    padding: 4px;
    border:1px solid #000;
}

th{
	padding: 6px;
    border:1px solid #000;
}
</style>