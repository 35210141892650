<template v-if="emitter_material">
    <li v-bind:value="JSON.stringify({
        id: emitter_material.id,
        name: emitter_material.name
    })">
    <div class="columns is-mobile has-text-left">
            <div class="column is-3">   
                {{ emitter_material.name }}
            </div>
            <div class="column is-3">
                
                <vue-google-autocomplete
                    ref="material_address"
                    v-bind:id=JSON.stringify(emitter_material.id)
                    class="input"
                    placeholder="Enter source"
                    v-on:placechanged="getFromAddress"
                    country="ug"
                    types=""
                    v-on:error="handleError"
                >
                </vue-google-autocomplete>
            </div>
            <div class="column is-3">
                <div class="dropdown" id="drop">
                    <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" onclick="this.parentNode.parentNode.classList.toggle('is-active')">
                        <span v-if="this.selectedValue ==''">Select Mode:</span>
                        <span v-else>{{ selectedValue }}</span>
                        <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                        </span>
                    </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a class="dropdown-item" v-for="option in options" :key="option.id" @click="selectOption(option)">
                        {{ option.label }}
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            <div class="column is-3 field"> 
                <div class="control">
                <input type="number" class="input" @change="addToCart()"
                    placeholder="Enter number of trips"
                    v-model="number_of_trips"                  
                    >
                </div> 
            </div>
        </div>
        <hr class="mt-0">
    </li>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import store from '@/store/index.js';
import swal from 'sweetalert';
export default{
    name : "EmitterMaterial",
    props:{
        emitter_material: Object
    },
    data(){
        return {
            material_address:'',
            number_of_trips:'',
            distance:'',
            isOpen: false,
            selectedValue: '',
            fuel_efficiency:'',
            options: [
                { id: 1, label: 'Sino truck', fuel_efficiency: 15 },
                { id: 2, label: 'Forward truck', fuel_efficiency: 12  },
                { id: 3, label: 'Elf truck', fuel_efficiency: 8 },
            ]
        }
    },
    components:{
        VueGoogleAutocomplete
    },
    mounted(){

    },
    methods:{
        toggleDropdown() {
            this.isOpen = !this.isOpen
        },
        selectOption(option) {
            this.selectedValue = option.label
            this.fuel_efficiency = option.fuel_efficiency
            const element = document.getElementById('drop')
            element.classList.remove('is-active')
        },

        getFromAddress: function (addressData, placeResultData, id) {
        this.address = addressData;
        this.placeResultData = placeResultData
        
        //get site location lat and long
        

        const destination = new google.maps.LatLng(this.$store.state.carbon_site_location.latitude, this.$store.state.carbon_site_location.longitude)
        const origin = new google.maps.LatLng(this.address.latitude, this.address.longitude)

        
        
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode:'DRIVING',
            unitSystem: google.maps.UnitSystem.metric,
            avoidHighways: false,
            avoidTolls: false
        }, callback);

        function callback(response, status){
          if (status != google.maps.DistanceMatrixStatus.OK){
            $('#result').html(err);
          }else {
            var origin = response.originAddresses[0];
            console.log(origin);
            var destination = response.destinationAddresses[0];
            console.log(destination);
            if (response.rows[0].elements[0].status === 'ZERO_RESULTS'){
              $('#result').html(
                'Please enter a site location or a location near the site'
              );
            }else {
              var distance_got = response.rows[0].elements[0].distance;
              console.log(distance_got);
              const distance_in_kilo = distance_got.value / 1000;
              
              store.commit("setCarbonCartDistance", distance_in_kilo)
              //this.distance = distance_in_kilo
              console.log(distance_in_kilo)
            }
          }
          }
      },
      addToCart(){
        if (isNaN(this.number_of_trips) || this.number_of_trips < 1){
                this.number_of_trips = 1
        }
        if (isNaN(this.mode) || this.mode === ''){
                this.mode = 15
        }
        if (isNaN(this.$store.state.carbon_cart_distance) || this.$store.state.carbon_cart_distance === 0){
            swal({
              title: "Sorry!",
              text:"Enter location for source of the material",
              icon:"warning",
              button: "OK"
        })
        }
        const item = {
                emitter_material: this.emitter_material,
                distance: this.$store.state.carbon_cart_distance,
                number_of_trips: this.number_of_trips,
                fuel_efficiency: this.fuel_efficiency,
                mode: this.selectedValue
             }
        console.log(item)
             this.$store.commit("addToCarbonCartMat", item)
        console.log(this.$store.state.carbon_cart.emit_mats)
    }
}
}
</script>

<style scoped>

</style>