<template>
    <div class="section pt-1 ">
        <div class="section has-text-left pl-0">
            <button @click="goBack" class="button is-success">
                <i class="fas fa-arrow-left "> back</i>
            </button>
        </div>
        <div class="columns is-multiline">
            <div class="column is-12 has-text-left">
                <h1 class="title">Quotation details</h1>

                
                    <div class="field">
                        <label>Name</label>
                        <div class="control">
                            <input type="text" class="input" v-model="client.name">
                        </div>
                    </div>

                    <div class="field">
                        <label>Company Name</label>
                        <div class="control">
                            <input type="company_name" class="input" v-model="client.company_name">
                        </div>
                    </div>

                    <div class="field">
                        <label>Phone Contact</label>
                        <div class="control">
                            <input type="phone_contact"
                            placeholder="start with +256"
                             class="input" v-model="client.phone_contact">
                        </div>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input" v-model="client.email">
                        </div>
                    </div>

                    

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button @click="submitForm" class="button is-success">Proceed to Print Quotation</button>
                        </div>
                    </div>



                    <hr>         
                
            </div>
        </div>
    </div>
</template>



<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'Register',
    data() {
        return {
            client:{},
            errors: [],
            phone_contact: +256,

        } 
    },
    mounted(){
        document.title = 'Quotation Details | Tofali Africa'
    },
    methods: {
        submitForm(){
            
            this.errors = []

            if (this.client.name === '') {
                this.errors.push('The name is missing')
            }
            if (this.client.company_name === '') {
                this.errors.push('The company name is missing')
            }
            if (this.client.phone_contact === '') {
                this.errors.push('The phone contact is missing')
            }
            if (this.client.email === '') {
                this.errors.push('The email is missing')
            }

            if (!this.errors.length) {
                const formData = {
                    name: this.name,
                    company_name: this.company_name,
                    phone_contact: this.phone_contact,
                    email: this.email
                }
            
            //set state to add user info
            this.$store.commit('setName', this.client.name)
            this.$store.commit('setEmail', this.client.email)
            this.$store.commit('setPhone', this.client.phone_contact)
            this.$store.commit('setCompany', this.client.company_name)

            axios
                .post("/api/v1/clients/", this.client)
                .then(response => {

                    this.$router.push('/results')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })   


            }
        },
        goBack(){
            this.$router.go(-1)
        }
    }
}

</script>

<style scoped>
.is-success{
  background-color:#004100;
}
</style>