<template>
    <tr>
        <td><strong>{{ item.material_manufacture.material_manufacture.name }}</strong></td>
        <td>{{ item.material_manufacture.material_item }}</td>
        <td>{{ item.amount_of_material }}</td>
        <td>{{ item.material_manufacture.unit }}</td>
        <td>{{ item.material_manufacture.emission_factor }}</td>
        <td v-if="item.material_manufacture.material_manufacture.name === 'Cement'">{{ getItemEmissionCement(item).toFixed(2) }}</td>
        <td v-if="item.material_manufacture.material_manufacture.name === 'Steel'">{{ getItemEmissionSteel(item).toFixed(2) }}</td>
        <td v-if="item.material_manufacture.material_manufacture.name === 'Concrete Blocks'">{{ getItemEmissionBlock(item).toFixed(2) }}</td>

    </tr>
</template>

<script>
export default{
    name : "EmitterManItem",
    props:{
        initialItem: Object
    },
    data(){
        return{
            item: this.initialItem,
            carbon_cart: {
                emit_mans:[]
            }
        }
    },
    mounted(){
        this.carbon_cart = this.$store.state.carbon_cart
    },
    methods:{
        getItemEmissionCement(item){
            return (item.amount_of_material * item.material_manufacture.emission_factor * 50)
            
        },
        getItemEmissionSteel(item){
            if(item.material_manufacture.material_item === 'T25'){
                return (item.amount_of_material * item.material_manufacture.emission_factor * 3.14 * 0.0125 * 0.0125 * 12 * 7852)
            } else if ( item.material_manufacture.material_item === 'T16') {
                return (item.amount_of_material * item.material_manufacture.emission_factor * 3.14 * 0.008 * 0.008 * 12 * 7852 )
            } else if (item.material_manufacture.material_item === 'T12'){
                return (item.amount_of_material * item.material_manufacture.emission_factor * 3.14 * 0.006 * 0.006 * 12 * 7852 )
            } else if (item.material_manufacture.material_item === 'T8'){
                return (item.amount_of_material * item.material_manufacture.emission_factor * 3.14 * 0.004 * 0.004 * 12 * 7852 )
            }
        },
        getItemEmissionBlock(item){
            return (item.amount_of_material * item.material_manufacture.emission_factor)
        }
    }
    
}
</script>