<template>
    <div>
    <div class="section page-log-in">
        <div class="columns has-text-left">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Log in</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input" v-model="username">
                        </div>
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success" style="background-color:#004100;">Log in</button>
                        </div>
                    </div>
                    <router-link to="/reset-email">
                        <p>Forgot Password?</p>  
                    </router-link>

                    <hr>

                    Or <router-link to="/sign-up">click here</router-link> to sign up!
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
//import TopBar from '@/components/TopBar.vue'
export default {
    name: 'LogIn',
    data() {
        return {
            username: '',
            password: '',
            errors: []
        }
    },
    components:{
        //TopBar
    },
    mounted() {
        document.title = 'Log In | Tofali Africa'
    },
    methods: {
     submitForm() {
            axios.defaults.headers.common["Authorization"] = ''

            localStorage.removeItem("access")

            const formData = {
                email: this.username,
                password: this.password
            }

            axios
                .post("/api/v1/jwt/create/", formData)
                .then(response => {
                    //console.log(respose)
                    const access= response.data.access
                    const refresh = response.data.refresh
                                   
                    this.$store.commit('setAccess', access)
                    this.$store.commit('setRefresh', refresh)
                    this.$store.commit('setUsername', this.username)
                    
                    axios.defaults.headers.common["Authorization"] = "JWT " + access

                    localStorage.setItem("access", access)
                    localStorage.setItem("refresh", refresh)
                    //localStorage.setItem("username", this.username)
                    
                    const toPath = this.$route.query.to || '/'

                    this.$router.push(toPath)

                    //if (response.status === 200) {
                        //console.log(username)
                        //this.$router.push({ name:'Cart' })
                        //localStorage.setItem('username', username);
                    //}}
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')   
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}


</script>

<style scoped>
.is-success {
    background-color:#004100;
}
</style>