<template>
    <tr>
        <td><strong>{{ item.emitter_stage.activity.stage.name }}</strong></td>
        <td>{{ item.emitter_stage.name }} <p>{{ item.emitter_stage.activity.name }}</p></td>
        <td>{{ item.fuel_type }}</td>
        <td>{{ item.units }}</td>
        <td> {{ item.number_of_litres }}</td>
        <td>{{ item.emission_factor }}</td>
        <td>{{ getItemEmission(item).toFixed(2) }}</td>
    </tr>
</template>

<script>
export default{
    name : 'EmitterStageItem',
    props:{
        initialItem: Object
    },
    data(){
        return {
            item: this.initialItem,
            carbon_cart: {
                emit_stages:[]
            }
        }
    },
    mounted(){
        this.carbon_cart = this.$store.state.carbon_cart
    },
    methods:{
        getItemEmission(item){
            return (item.number_of_litres * item.emission_factor)
        }
    }
}
</script>