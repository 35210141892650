<template>
  <tr>
    <td><strong>Electricity</strong></td>
    <td>kWh</td>
    <td>{{ this.$store.state.carbon_cart_electricity }}</td>
    <td>0.582</td>
    <td>
      {{ (this.$store.state.carbon_cart_electricity * 0.582).toFixed(2) }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "EmitterElectricity",
  methods: {
    getEmissionElec() {
      return this.$store.state.carbon_cart_electricity * 0.582;
    },
  },
};
</script>
