<template v-if="material_manufacture">
<li v-bind:value="JSON.stringify({
        id: material_manufacture.id,
        material_manufacture: material_manufacture.material_manufacture,
        material_item: material_manufacture.material_item,
        emission_factor: material_manufacture.emission_factor
    })">
    <div class="columns is-mobile has-text-left">
            <div class="column is-3">   
                {{ material_manufacture.material_manufacture.name }}
            </div>
            <div class="column is-3">
                {{ material_manufacture.material_item }}
            </div>
            <div class="column is-3">
                {{ material_manufacture.unit }}
            </div>
            <div class="column is-3">
                <input  type="number" class="input" @change="addToCart()"
                placeholder="Amount used"
                v-model.number="amount_of_material"               
                >
            </div>
    </div>
</li>

</template>

<script>
export default {
    name : "EmitterManufacture",
    props: {
        material_manufacture:Object
    },
    data(){
        return{
            //amount_of_material:'',
            
        }
    },
    methods:{
        addToCart(){
            if (isNaN(this.amount_of_material) || this.amount_of_material < 1){
                this.amount_of_material = 1
        }

        const item = {
            material_manufacture: this.material_manufacture,
            amount_of_material: this.amount_of_material
        }
        console.log(item)
        this.$store.commit("addToCarbonCartMans", item)
        console.log(this.$store.state.carbon_cart.emit_mans)
        }
        
    }
}
</script>

<style scoped>

</style>