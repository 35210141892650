<template>
    <div class="section">
        <h1 class="has-text-left is-size-4"><strong>Carbon Footprint Results For a Building</strong></h1>
        <div class="column section pt-2 is-12">
            <h2 class="has-text-left"><strong>Emissions due to Transportation</strong></h2>
            <table class="table" style="width:100">
                <thead>
                    <tr>
                        <th>Material</th>
                        <th>Distance from Source</th>
                        <th>Mode of Transport</th>
                        <th>No. of trips</th>
                        <th>Fuel consumption of vehicle (l/100km)</th>
                        <th>Fuel consumed on journey</th>
                        <th>Carbon Emission Factor</th>
                        <th>Carbon footprint (kgCO2-e)</th>
                    </tr>
                </thead>
                <tbody>
                    <EmitterMatItem
                    v-for="item in carbon_cart.emit_mats"
                    v-bind:key="item.emitter_material.id"
                    v-bind:initialItem="item"
                    />
                    <tr>
                        <th colspan="6">CO2 emissions from transportation (kg of CO2eq)</th>
                        <td>{{ (getEmissionTransportation).toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="column section pt-2 is-12">
            <h2 class="has-text-left"><strong>Emissions due to Materials Use</strong></h2>
            <table class="table" style="width: 100;">
                <thead>
                    <tr>
                        <th>Material</th>
                        <th>Class</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Carbon Emission Factor</th>
                        <th>Carbon footprint (kgCO2-e)</th>
                    </tr>
                </thead>
                <tbody>
                    <EmitterManItem
                    v-for="item in carbon_cart.emit_mans"
                    v-bind:key="item.material_manufacture.id"
                    v-bind:initialItem="item"
                    />
                    <tr>
                        <th colspan="5">CO2 emissions from material usage</th>
                        <td>{{ (getEmissionManufacture).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column section pt-2 is-12">
            <h2 class="has-text-left"><strong>Emissions due to Construction Operations</strong></h2>
            <table class="table" style="width: 100;">
                <thead>
                    <tr>
                        <th>Stage</th>
                        <th>Equipment</th>
                        <th>Fuel type</th>
                        <th>Units</th>
                        <th>Quantity</th>
                        <th>Carbon Emission Factor</th>
                        <th>Carbon footprint (kgCO2-e)</th>
                    </tr>
                </thead>
                <tbody>
                    <EmitterStageItem
                        v-for="item in carbon_cart.emit_stage"
                        v-bind:key="item.emitter_stage.id"
                        v-bind:initialItem="item"
                    />
                    <tr>
                        <th colspan="6"><strong>CO2 emissions from construction operations</strong></th>
                        <td>{{ (getEmissionOperation).toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column section pt-2 is-12">
            <h2 class="has-text-left"><strong>Emissions due to Electricity consumption</strong></h2>
            <table class="table" style="width: 100;">
                <thead>
                    <tr>
                        <th>Electricity</th>
                        <th>Units</th>
                        <th>Quantity</th>
                        <th>Carbon Emission Factor (kgCO2-e/kWh)</th>
                        <th>Carbon footprint (kgCO2-e)</th>
                    </tr>
                </thead>
                <tbody>
                    <EmitterElectricity />
                    <tr>
                        <th colspan="4"><strong>CO2 emissions from electricity consumption</strong></th>
                        <td>{{ (getEmissionElectricity).toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column section is-12 pt-3">
            <h1 class="has-text-left is-size-4"> <strong>Total Project Emissions:       {{ getTotalEmissions }} kgC02-e</strong></h1>
        </div>

    </div>




</template>

<script>
import EmitterStageItem from '@/components/carbon/EmitterStageItem.vue';
import EmitterManItem from '@/components/carbon/EmitterManItem.vue';
import EmitterMatItem from '@/components/carbon/EmitterMatItem.vue';
import EmitterElectricity from '@/components/carbon/EmitterElectricity.vue';


export default{
    name: "CarbonResults",
    data() {
        return {
            carbon_cart: {
                emit_mats: [],
                emit_mans: [],
                emit_stage: [],
            },
            steel_density: 7852,
        };
    },
    components: {
    EmitterMatItem,
    EmitterManItem,
    EmitterStageItem,
    EmitterElectricity
},
    mounted(){
        document.title = "Results | Carbon Footprint | Tofali Africa"
        this.carbon_cart = this.$store.state.carbon_cart

    },
    computed: {
        getEmissionTransportation(){
            return this.carbon_cart.emit_mats.reduce((acc, curVal) => {
                return acc += (2.86767 * (curVal.distance
                * (curVal.fuel_efficiency / 100) * curVal.number_of_trips * 2))
            }, 0)
        },
        getEmissionManufacture(){
            const addition = this.$store.state.carbon_cart.emit_mans.reduce((acc, curVal) => {
                if(curVal.material_manufacture.material_manufacture.name === 'Cement'){
                    console.log(curVal.material_manufacture.material_manufacture.name)
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * 50)
                } else if(curVal.material_manufacture.material_item === 'T16') {
                    console.log(curVal.material_manufacture.material_item)
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * 3.14 * 0.008 * 0.008 * 12 * this.steel_density)
                } else if(curVal.material_manufacture.material_item === 'T12'){
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * 3.14 * 0.006 * 0.006 * 12 * this.steel_density)
                } else if(curVal.material_manufacture.material_item === 'T8'){
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * 3.14 * 0.004 * 0.004 * 12 * this.steel_density)
                } else if(curVal.material_manufacture.material_item === 'T25'){
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * 3.14 * 0.0125 * 0.0125 * 12 * this.steel_density)
                } else if(curVal.material_manufacture.material_manufacture.name === 'Concrete Blocks'){
                    this.$store.commit('setCarbonManufactureTotal', this.$store.state.carbon_manufacture_total += curVal.amount_of_material * curVal.material_manufacture.emission_factor * curVal.amount_of_material)
                }
            }, 0)
            
            return (this.$store.state.carbon_manufacture_total).toFixed(2)
        },
        getEmissionOperation(){
            return this.carbon_cart.emit_stage.reduce((acc, curVal) => {
                return acc += (curVal.emission_factor * curVal.number_of_litres)
            }, 0)
        },
        getEmissionElectricity(){
            return this.$store.state.carbon_cart_electricity * 0.582
        },
        getTotalEmissions(){
            const transportation_sum = this.carbon_cart.emit_mats.reduce((acc, curVal) => {
                return acc += (2.86767 * (curVal.distance
                * (curVal.fuel_efficiency / 100) * curVal.number_of_trips * 2))
            }, 0)
            const manufacture_sum = this.$store.state.carbon_manufacture_total
            const operation_sum = this.carbon_cart.emit_stage.reduce((acc, curVal) => {
                return acc += (curVal.emission_factor * curVal.number_of_litres)
            }, 0)
            const electricity_sum = this.$store.state.carbon_cart_electricity * 0.582
            return (transportation_sum + manufacture_sum + operation_sum + electricity_sum).toLocaleString()
        }

    },

}
</script>

<style scoped>


</style>